import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import Loading from "../Components/Loading/Loading";

const Login = React.lazy(() => import("../Components/Login/Login"));

const UnAuthRoute = (props) => {
  return (
    <Suspense fallback={<Loading />}>
      <Routes>
        <Route path="/" element={<Login {...props} />} />
      </Routes>
    </Suspense>
  );
};
export default UnAuthRoute;
