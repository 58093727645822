import React from "react";
import ValidateFlow from "./ValidateFlow/ValidateFlow";
import { ReactNotifications } from "react-notifications-component";

function App(props) {
  return (
    <>
      <ReactNotifications />
      <ValidateFlow {...props} />
    </>
  );
}

export default App;
