import { url } from "./configuration";
import { Store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";

export const loginURL = url + "/private/v1/admin/login";
export const adminloginsessionURL = url + "/private/v1/admin/loginsession";
export const logoutURL = url + "/private/v1/admin/logout";
export const tradesURL = url + "/private/v1/trade/trades";
export const userListURL = url + "/private/v1/users/userList";
export const userMembershipURL = url + "/private/v1/users/membership";

export const showNotificationMsz = (message, messageType) => {
  Store.addNotification({
    title: "",
    message: `${message}`,
    type: `${messageType}`,
    insert: "top-center",
    container: "top-center",
    dismiss: {
      duration: 3000,
      onScreen: true,
    },
  });
};

export const getDate = (timestamp) => {
  let parseValue = new Date(parseInt(timestamp)).toString();
  let newTime = parseValue.substring(16, 21);
  let returnDate = {
    year: parseValue.substring(11, 15),
    date: parseValue.substring(8, 10),
    month: parseValue.substring(4, 7),
    time:
      newTime.split(":")[0] === "12"
        ? parseInt(newTime.split(":")[0]) +
          ":" +
          newTime.split(":")[1] +
          " " +
          (parseInt(parseInt(newTime.split(":")[0]) / 12) != 1 ? "AM" : "PM")
        : (parseInt(newTime.split(":")[0]) % 12) +
          ":" +
          newTime.split(":")[1] +
          " " +
          (parseInt(parseInt(newTime.split(":")[0]) / 12) != 1 ? "AM" : "PM"),
  };
  return returnDate;
};
export const emailValidator = (email) => {
  const regEx =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!email.match(regEx)) {
    return false;
  }
  return true;
};
export const blankValidator = (validators, key) => {
  if (
    validators === undefined ||
    validators === "" ||
    validators === null ||
    validators == {} ||
    validators == [] ||
    validators == -1 ||
    validators == NaN
  ) {
    return false;
  }
  return true;
};
