import React, { useEffect, useState } from "react";
import UnAuthRoute from "../ValidateFlow/UnAuthRoute";
import { connect } from "react-redux";
import RoutesAuthenticated from "../ValidateFlow/RoutesAuthenticated";
import { useLocation, useNavigate } from "react-router-dom";
import { islogin, userLogin } from "../Components/Common/commonConst";
import { adminloginsessionURL } from "../Components/Common/Common";
import { apihandler } from "../Components/Common/apicaller";
import Loader from "../Components/Common/Loader";

const ValidateFlow = (props) => {
  const [splashOpen, setSplashOpen] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    validateUserData();
  }, []);

  const validateUserData = async (tokenfromDevice) => {
    let token = tokenfromDevice || "";
    let { data, status } = await apihandler({}, adminloginsessionURL, "get");
    if (status === 1) {
      await props.dispatch({
        type: userLogin,
        value: data,
      });
      await props.dispatch({
        type: islogin,
        value: true,
      });
      setSplashOpen(false);
    } else {
      setSplashOpen(false);
    }
  };

  if (splashOpen) {
    return <Loader isLoading={splashOpen} />;
  } else {
    return (
      <>
        {props.ISLOGIN == true ? (
          <RoutesAuthenticated
            {...props}
            navigate={navigate}
            location={location}
          ></RoutesAuthenticated>
        ) : (
          <UnAuthRoute
            {...props}
            navigate={navigate}
            location={location}
          ></UnAuthRoute>
        )}
      </>
    );
  }
};
const mapStateToProps = function (state) {
  return {
    ISLOGIN: state[islogin] || "",
    USERLOGIN: state[userLogin] || "",
  };
};
export default connect(mapStateToProps)(ValidateFlow);
