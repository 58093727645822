import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import Loading from "../Components/Loading/Loading";

const DashBoard = React.lazy(() => import("../Components/DashBoard/DashBoard"));
const Trades = React.lazy(() => import("../Components/Trades/Trades"));
const Users = React.lazy(() => import("../Components/User/Users"));
const More = React.lazy(() => import("../Components/More/More"));

const RoutesAuthenticated = (props) => {
  return (
    <Suspense fallback={<Loading />}>
      <Routes>
        <Route path="/" element={<DashBoard {...props} />} />
        <Route path="/Trades" element={<Trades {...props} />} />
        <Route path="/Users" element={<Users {...props} />} />
        <Route path="/More" element={<More {...props} />} />
      </Routes>
    </Suspense>
  );
};
export default RoutesAuthenticated;
