// import {productList} from '../../../comman/commanConst';

const initialState = {
    islogin: false,
    userid: 10,
    ISLOADING: false,
    // PRODUCTLIST: undefined,
  };
  
  export default (state = initialState, action) => {
    try {
      let data = {};
      // if (action.type == productList) {
      //   let newProductdata = {...data[action.type], ...action.value};
      //   data[action.type] = newProductdata;
      // }
      //  else {
      data[action.type] = action.value;
      // }
  
      return { ...state, ...data };
    } catch (error) {
      return state;
    }
  };
  