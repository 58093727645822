import axios from "axios";

export const apihandler = async (body, url, method, isFile, Cookies) => {
  try {
    let payload = !isFile ? JSON.stringify(body) : body;
    let config;

    if (Object.keys(payload).length > 0 || isFile) {
      config = {
        method,
        url,
        headers: {
          "Content-Type": !isFile ? "application/json" : "multipart/form-data",
        },
        data: payload,
      };
    } else {
      config = {
        method,
        url,
        headers: {
          "Content-Type": "application/json",
        },
      };
    }

    let responseToUser = {
      data: "",
      status: 0,
      message: "No Internet Connection",
    };

    await axios(config, {
      withCredentials: true,
      credentials: "include",
    })
      .then((response) => {
        responseToUser = response.data;
      })
      .catch((error) => {
        console.log("catch error in api handler");
      });

    return responseToUser;
  } catch (error) {
    console.log("Usermanagement: loign error try block");
    return null;
  }
};

export const apihandlerwithoutWait = (body, url, method, isFile, Cookies) => {
  try {
    let payload = isFile ? JSON.stringify(body) : body;
    let config;
    if (Object.keys(payload).length > 0) {
      config = {
        method,
        url,
        headers: {
          "Content-Type": "application/json",
        },
        data: payload,
      };
    } else {
      config = {
        method,
        url,
        headers: {
          "Content-Type": "application/json",
        },
      };
    }
    return axios(config);

    return;
  } catch (error) {
    return null;
  }
};
